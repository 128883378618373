import React from 'react';

function Group() {
  return (
    <div data-nosnippet>
    <br />
    <p><b>  PhD students:</b>
    <br /></p>
    <ul>
    <li><a href="https://inf.ethz.ch/people/people-atoz/person-detail.MjE2NzAw.TGlzdC8zMDQsLTIxNDE4MTU0NjA=.html">Tom Kuchler</a></li>
    <li><a href="https://cvetkovic.github.io/">Lazar Cvetković</a></li>
    <li><a href="https://fotstrt.github.io/">Foteini Strati</a></li>
    <li><a href="https://mboether.com/">Maximilian Böther</a></li>
    <li><a href="https://about.yao.sh/">Xiaozhe Yao</a></li>
    <li><a href="https://www.linkedin.com/in/mihajlo-djokic/">Mihajlo Djokic</a></li>
    <li><a href="https://www.linkedin.com/in/pinghe-li-5aa407226/">Pinghe Li</a></li>
    </ul>


    <p><b>  Postdocs:</b>
    <br /></p>
    <ul>
    <li><a href="https://yazhuozhang.com/">Yazhuo Zhang</a></li>
    </ul>


    <p><b>  Masters students:</b>
    <br /></p>
    <ul>
    <li><a href="https://www.linkedin.com/in/paul-elvinger-a0b304160/">Paul Elvinger</a></li>
    <li><a href="https://www.linkedin.com/in/bggoranoff/?originalSubdomain=ch">Boris Goranov</a></li>
    <li><a href="https://www.linkedin.com/in/victor-vitez-622778156">Victor Vitez</a></li>
    <li><a href="https://www.linkedin.com/in/jonathanplsmith/">Jonathan Smith</a></li>
    <li><a href="https://www.linkedin.com/in/alessio-russo-a45601323/">Alessio Russo</a></li>
    <li><a href="https://www.linkedin.com/in/tobias-stocker-738638227/">Tobias Stocker</a></li>
    <li><a href="https://www.linkedin.com/in/boyko-borisov/">Boyko Borisov</a></li>
    <li><a href="https://www.linkedin.com/in/eric-nothum/">Eric Nothum</a></li>
    <li><a href="https://www.linkedin.com/in/beste-guney-23b5541b1/">Beste Guney</a></li>
    <li><a href="https://www.linkedin.com/in/hongshu-yan-7b9b79175/">Hongshu (Hazel) Yan</a></li>
    <li><a href="https://www.linkedin.com/in/gmanos/">George Manos</a></li>
    <li><a href="https://www.linkedin.com/in/zhendong-zhang-7901bb23a/">Zhendong Zhang</a></li>
    <li><a href="https://www.linkedin.com/in/rongzhi-li">Rongzhi Li</a></li>
    </ul>

    <p><b>  Bachelor students:</b>
    <br /></p>
    <ul>
    <li><a href="https://www.linkedin.com/in/tim-notter-3b79a6234/">Tim Notter</a></li>
    <li><a href="https://robinh.me/">Robin Holzinger</a></li>

    </ul>
    
    <p><b>  Visitors:</b>
    <br /></p>
    <ul>
    <li><a href="https://tonyhao.xyz/">Qinghao Hu</a></li>
    <li><a href="https://www.unibo.it/sitoweb/lorenzo.rosa/en">Lorenzo Rosa</a></li>
    </ul>

    <div className="accordion pb-5 pt-3" id="accordionExample" style={{width:"100%"}}>
      <div className="card">
        <div className="card-header" id="headingOne">
          <h2 className="mb-0">
            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Alumni
            </button>
          </h2>
        </div>
        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div className="card-body">
          <b>  Post-docs:</b>
          <ul>
            <li><a href="https://ustiugov.github.io/">Dmitrii Ustiugov</a>, now Assistant Professor at Nanyang Technological University, Singapore</li>
          </ul>

          <b>  PhD students:</b>
          <ul>
           <li><a href="https://dangraur.github.io/">Dan Graur</a> (primary advisor: <a href="https://people.inf.ethz.ch/alonso/alonso_page.html">Prof. Gustavo Alonso</a>), now at Google</li>
            <li><a href="https://scholar.google.com/citations?user=HXGV2z0AAAAJ&hl=en">Michael Wawrzoniak</a> (primary advisor: <a href="https://people.inf.ethz.ch/alonso/alonso_page.html">Prof. Gustavo Alonso</a>)</li>
            <li><a href="https://dkoutsou.github.io/">Dimitris Koutsoukos</a> (primary advisor: <a href="https://people.inf.ethz.ch/alonso/alonso_page.html">Prof. Gustavo Alonso</a>), now at Apple</li>
          </ul>

          <b> Masters thesis students:</b> 
          <ul>
            <li><a href="https://www.linkedin.com/in/xianzhe-ma/">Xianzhe Ma</a></li>
            <li><a href="https://www.linkedin.com/in/ixeia-s-p/">Ixeia Sánchez Périz</a></li>
            <li><a href="https://www.linkedin.com/in/jingyi-zhu-jenny/?originalSubdomain=ch">Jingyi Zhu</a></li>
            <li><a href="https://www.linkedin.com/in/estellanegoita/">Estella Negoita</a></li>
            <li>Francesco Deaglio</li>
            <li><a href="https://www.linkedin.com/in/oto-mraz-643270192">Oto Mraz</a></li>
            <li><a href="https://www.linkedin.com/in/mihajlo-djokic-aa0b6b159/?originalSubdomain=ch">Mihajlo Djokić</a></li>
            <li><a href="https://www.linkedin.com/in/elwin-stephan/?originalSubdomain=ch">Elwin Stephan</a></li>
            <li><a href="https://www.linkedin.com/in/dan-kluser-4221ba1a8/">Dan Kluser</a> </li>
            <li><a href="https://github.com/zkck">Zak Cook</a></li>
            <li><a href="https://www.linkedin.com/in/muyu-li/">Muyu Li </a></li>
            <li><a href="https://www.linkedin.com/in/zehao-wei-a6995812b/">Zehao Wei</a></li>
            <li><a href="https://www.linkedin.com/in/hengxuan-y/">Hengxuan Ying</a></li>
            <li><a href="https://www.linkedin.com/in/malte-brodmann-480719223/">Malte Brodmann</a></li>
            <li><a href="https://www.linkedin.com/in/kumar-mohit-503617a5/">Mohit Kumar</a></li>
            <li><a href="https://www.linkedin.com/in/valentino-foehn/">Valentino Föhn</a></li>
            <li><a href="https://www.linkedin.com/in/amoryhoste/">Amory Hoste</a></li>
          </ul>
          <b> Bachelor thesis students: </b>
          <ul>
            <li><a href="https://www.linkedin.com/in/jonathanplsmith/">Jonathan Smith</a></li>
            <li><a href="https://www.linkedin.com/in/leon-thomm-8724661bb/">Leon Thomm</a></li>
            <li>Robin Oester</li>
            <li><a href="https://www.linkedin.com/in/joel-andr%C3%A9-b771ba18b/?originalSubdomain=ch">Joel André</a></li>
            <li>Simon Sommerhalder</li>
          </ul> 
          <b> Research assistants: </b>
          <ul>
            <li><a href="https://scholar.google.com/citations?user=yNva1yMAAAAJ&hl=en">Yushi Liu</a></li>
            <li><a href="https://www.linkedin.com/in/neolou">Neo Lou</a></li>
            <li>Josef Schönberger</li>
            <li><a href="https://vgsteiger.com/">Viktor Gsteiger</a></li>
            <li><a href="https://www.linkedin.com/in/francois-costa-ethz/">François Costa</a></li>
            <li>Eirini Miliori</li>
            <li><a href="https://www.linkedin.com/in/dohyun1357/">Dohyun Park</a></li>
            <li><a href="http://hongyu.nl/about/">Hongyu He</a></li>
            <li><a href="https://www.linkedin.com/in/kaishuo-kai-zhang-6587a5135/">Kaishuo Zhang</a></li>
            <li><a href="https://www.linkedin.com/in/wubowen-cs/">Bowen Wu</a></li>
            <li><a href="https://www.linkedin.com/in/julia-bazinska/">Julia Bazińska</a></li>
            <li><a href="https://www.linkedin.com/in/tanguy-albrici-82404b172/">Tanguy Albrici</a></li>
            <li><a href="https://www.linkedin.com/in/damien-aymon">Damien Aymon</a></li>

          </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}


//	    <!-- Semester project students -->
//	    <!-- <li><a href="https://www.linkedin.com/in/fangyun-lin-4b1153184/">Fangyun Lin</a></li> -->
//	    <!-- <li><a href="https://www.linkedin.com/in/ma%C5%A1a-ne%C5%A1i%C4%87/">Maša Nešić</a></li> -->
//	    <!-- <li><a href="https://www.linkedin.com/in/viktor-gsteiger/">Viktor Gsteiger</a></li> -->
//	    <!-- <li><a href="https://www.linkedin.com/in/jingyi-zhu-jenny">Jingyi Zhu</a></li> -->
//	    <!-- <li><a href="https://www.linkedin.com/in/georgijs-vilums-a8a45b1ab/">Georgijs Vilums</a></li> -->
//	    <!-- <li><a href="https://www.linkedin.com/in/dragancea-constantin/">Constantin Dragancea</a></li> -->
//	    <!-- <li>Pinghe Li</li> -->

export default Group;
